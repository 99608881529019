import React from 'react'
import Layout from '../../Layout/Layout'

const ContactUsPage = () => {
  return (
    <Layout>
      <h1 className='d-flex justify-content-center my-5'>Coming Soon.......</h1>
    </Layout>
  )
}

export default ContactUsPage
