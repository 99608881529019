 import React, { useState } from 'react';

const Mobile_Footer_Dropdown = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = (props) => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className='mobile_footer_dropdown'>
      <button className='sub_section_header' onClick={toggleDropdown}>
        {props.label}
        {isDropdownVisible ? (
          <svg
            className='float-end'
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M15 12.5L10 7.5L5 12.5'
              stroke='white'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ) : (
          <svg
            className='float-end'
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M5 7.5L10 12.5L15 7.5'
              stroke='white'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        )}
      </button>
      {isDropdownVisible && props.data}
    </div>
  );
};

export default Mobile_Footer_Dropdown;
