import React from 'react'
import Title_and_Text from '../../../global_components/Title_and_Text/Title_and_Text'
import CarouselComponent from './CarouselComponent'
import { Container } from 'react-bootstrap';

const Section03 = () => {
  return (
    <Container className='mb-global'>
      <Title_and_Text 
        title_black={"What "} 
        title_purple={"we do"}
        text={"With our extensive experience in logistics, we have developed an efficient optimization to offer reliable and competitive shipping services for your cargo anywhere across the globe."}
        text_box_width={"800px"}
      />
      <CarouselComponent/>
    </Container>
  )
}

export default Section03
