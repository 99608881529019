import React, { useEffect } from 'react'
import Layout from '../../Layout/Layout'
import Section01 from './Section01'
import Section02 from './Section02'

const SevicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
        <Section01/>
        <Section02/>
    </Layout>
      
  )
}

export default SevicesPage
