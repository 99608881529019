import React from 'react'

const DataCard = (props) => {
  return (
    <div className='data-card'>
      <div className="circle">{props.id}</div>
      <div className="title">{props.title}</div>
      <div className="text">{props.text}</div>
    </div>
  )
}

export default DataCard
