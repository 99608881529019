import React from 'react'
import PrimaryBanner from '../../../global_components/Primary Banner/PrimaryBanner'
// import Banner from '../../../../assets/media/about us/Banner.webp'
import Banner from '../../../../assets/media/1.jpg'
import BannerMobile from '../../../../assets/media/1-3.jpg'

const Section01 = () => {
  return ( <PrimaryBanner src={Banner} src_mob={BannerMobile} banner_text={"About Us"} alt={"About Us"}/> )
}

export default Section01
