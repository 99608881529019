import React, { useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import Primary_Component from './Sub-Service Primary Component/Primary_Component'
import sectionImage from '../../../../assets/media/services/petrochem/section-banner.webp'

const PetrochemLogistics = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
        <Primary_Component
            mobileBanner_src={sectionImage}
            sectionImage_src={sectionImage}
            alt={'Petrochem Logistics'}
            title={'Petrochem Logistics'}
            text={'We ensure your products get where they need to be by handling everything from the initial quote with diligence from origin to final destination.'}
            serviceName={"petrochem logistics"}
        />
    </Layout>
    
  )
}

export default PetrochemLogistics
