import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import Title_and_Text from '../../../global_components/Title_and_Text/Title_and_Text'
// import sectionImage from "../../../../assets/media/about us/second section image.webp"
import sectionImage from "../../../../assets/media/about us/about-us-second-section.webp"
import '../AboutUs.css'

const Section02 = () => {
  return (
    <Container className='mb-global history-section'>
        <div className='text-box'>
            <Title_and_Text 
                title_black={"Our Company "} 
                title_purple={"History"} 
                text={<div className='dynamic-text-align'> RAH shipping and Logistics is the leading full-service freight and cargo solution provider in the UAE. We have handled cargo services ranging from break-bulk shipment and re-export shipment handling to project cargo. Our team is committed to offering total, customized solutions for any logistics requirement for individuals and businesses. </div>}
                text_box_width={"540px"}
            />
        </div>
        <div className='image-box'>
            <Image src={sectionImage} alt='our history' />
        </div>
    </Container>
  )
}

export default Section02
