import React from "react";
import Header from "./Header/Header.jsx";
import Footer from "./Footer/Footer";
import Copyright from "./Copyright/Copyright";

const Layout = ({ children }) => {
    return(
        <>
            <Header/>
            <div className="d-flex"></div>
            <main>{ children }</main>
            <Footer/>
            <Copyright/>
        </>
    )
}

export default Layout;