import React from 'react'
import { Container, Image } from 'react-bootstrap'
import './PrimaryBanner.css'

const PrimaryBanner = (props) => {
  return (
    <Container className='primary-banner'>
        <Image src={props.src} alt={props.alt} className='primary-banner-desk'/>
        <Image src={props.src_mob} alt={props.alt} className='primary-banner-mob'/>
        <div className='primary-banner-text'> {props.banner_text} </div>
    </Container>
  )
}

export default PrimaryBanner
