import React, { useState, useEffect } from 'react';
import Desktop_Footer from './Desktop_Footer';
import Mobile_Footer from './Mobile_Footer';

const Footer = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? <Mobile_Footer /> : <Desktop_Footer />}
    </>
  );
}

export default Footer;
