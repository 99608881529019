import React, { useState } from 'react'
import Input_field from './Input_field';
import FileInput from './FileInput';
import Submit_button from './Submit_button';
import axios from 'axios';

const ExportForm = () => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const uploadFileSvg = 
    <svg className='me-1 mb-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M3 12.75V14.25C3 15.0784 3.67157 15.75 4.5 15.75H13.5C14.3284 15.75 15 15.0784 15 14.25V12.75" stroke="#575656" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 12.75V14.25C3 15.0784 3.67157 15.75 4.5 15.75H13.5C14.3284 15.75 15 15.0784 15 14.25V12.75" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.25 6.75L9 3L12.75 6.75" stroke="#575656" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.25 6.75L9 3L12.75 6.75" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 3V12" stroke="#575656" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 3V12" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    // const [bill_of_landing, setBillOfLanding] = useState(null);
    const [document, setDocument] = useState(null);
    const [id_copy, setIdCopy] = useState(null);
    const [documentFileSizeError, setDocumentFileSizeError] = useState("");
    const [idCopyFileSizeError, setIdCopyFileSizeError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const [formData, setFormData] = useState({
        form: 'Export Form',
        user_name: '',
        contact_number: '',
        email: '',
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleDocument = (event) => {
      const selectedFile = event.target.files[0];
      const maxSize = 2097152; // 2MB
  
      if (selectedFile) {
        if (selectedFile.size > maxSize) {
          setDocument(null); 
          setDocumentFileSizeError("File size exceeds the maximum allowed size (2MB).");
          setTimeout(() => {
            setDocumentFileSizeError("");
          }, 3000);
        } else {
          setDocument(selectedFile);
          setDocumentFileSizeError("");
        }
      }
    };
  
    const handleIdCopy = (event) => {
      const selectedFile = event.target.files[0];
      const maxSize = 2097152; // 2MB
  
      if (selectedFile) {
        if (selectedFile.size > maxSize) {
          setIdCopy(null); 
          setIdCopyFileSizeError("File size exceeds the maximum allowed size (2MB).");
          setTimeout(() => {
            setIdCopyFileSizeError("");
          }, 3000);
        } else {
          setIdCopy(selectedFile);
          setIdCopyFileSizeError("");
        }
      }
    };
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      setIsLoading(true);
  
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('form', formData.form);
      formDataToSubmit.append('user_name', formData.user_name);
      formDataToSubmit.append('contact_number', formData.contact_number);
      formDataToSubmit.append('email', formData.email);
      formDataToSubmit.append('document', document);
      formDataToSubmit.append('id_copy', id_copy);
      console.log(formData);
  
      try {
        const response = await axios.post(
          // 'https://www.rahshiplog.com/shipEmail/sendEmailContact2.php',
        'https://www.rahshiplog.com/shipEmail/sendEmailContactDev.php',
          formDataToSubmit
        );
  
        setIsLoading(false);
        setIsSuccess(true);
        setFormData({
            form: 'Export Form',
            user_name: '',
            contact_number: '',
            email: ''
        });
        setDocument(null);
        setIdCopy(null); 
        setTimeout(resetStatus, 3000);
      } catch (error) {
        console.error('Error sending data:', error);
        setIsLoading(false);
        setIsError(true);
        setErrorMessage('An error occurred while submitting the form.');
        setTimeout(resetStatus, 3000);
      }
    };
  
    const resetStatus = () => {
      setIsSuccess(false);
      setIsError(false);
      setErrorMessage('');
    };


  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data"> 
        <Input_field type="hidden" name="form_name" value="Export-Form"/>
        <Input_field type="text" name="user_name" placeholder="Full Name*" value={formData.user_name} onChange={handleInputChange} required={true}/>
        <Input_field type="number" class={'contactInput'} name="contact_number" placeholder="Contact Number*" value={formData.contact_number} onChange={handleInputChange} required={true}/>
        <Input_field type="email" name="email" placeholder="Email*" value={formData.email} onChange={handleInputChange} required={true}/>
        
        {/* <div style={{textAlign: 'left'}}>
            <label htmlFor='document' style={{ cursor: 'pointer' }} className='form-input-field'>
              {document ? document.name : <> {uploadFileSvg} {'Attach Document'}</>}
            </label>
            <input type="file" name="document" id='document' onChange={handleDocument} style={{ visibility: 'hidden', height: '0', width: '0' }}  accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"/>
        </div> */}

          <div style={{ textAlign: 'left' }}>
            <label htmlFor='document' style={{ cursor: 'pointer' }} className='form-input-field'>
              {document ? document.name : <> {uploadFileSvg} {'Attach Document'}</>}
            </label>
            <input type="file" name="document" id='document' onChange={handleDocument} style={{ visibility: 'hidden', height: '0', width: '0' }}  accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"/>
            {documentFileSizeError && <div className='text-danger m-2'>{documentFileSizeError}</div>}
          </div>

          <div style={{textAlign: 'left'}}>
              <label htmlFor='id' style={{ cursor: 'pointer' }} className='form-input-field'>
                {id_copy ? id_copy.name : <> {uploadFileSvg} {'Attach ID Copy'}</>}
              </label>
              <input type="file" name="id_copy" id='id' onChange={handleIdCopy} style={{ visibility: 'hidden', height: '0', width: '0' }}  accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"/>
              {idCopyFileSizeError && <div className='text-danger m-2'>{idCopyFileSizeError}</div>}
          </div>

        {/* <input className='form-input-field' type="file" name="document" id='document' onChange={handleDocument} required/> */}
        {/* <input className='form-input-field' type="file" name="id_copy" id='id' onChange={handleIdCopy}/> */}
        <Submit_button value={"Submit"} />
        {/* -----------------------------result----------------------------- */}
        {isLoading && (
            <div className="popup loader">Loading...</div>
        )}
        {isSuccess && (
            <div className="popup success">
                <div className="popup-message bg-success">Form submitted successfully!</div>
            </div>
        )}
        {isError && (
            <div className="popup error">
                <div className="popup-message bg-danger">{errorMessage}</div>
            </div>
        )}
    </form>
  )
}

export default ExportForm
