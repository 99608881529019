import React, { useEffect } from 'react'
import Layout from '../../Layout/Layout'
import PrimaryBanner from '../../global_components/Primary Banner/PrimaryBanner'
// import sectionImage from '../../../assets/media/e-services/second-section-image.webp'
import sectionImage from '../../../assets/media/e-services/eservice-second-section-image.webp'
// import Banner from '../../../assets/media/e-services/Banner.webp'
import Banner from '../../../assets/media/3.jpg'
import BannerMobile from '../../../assets/media/3-2.jpg'
import Center_Section_Primary from '../../global_components/Center Section/Center_Section_Primary'
import Section02 from './Section02/Section02'
import ImportExportForm from '../../Forms/ImportExportForm'
import ImportForm from '../../Forms/ImportForm'

const EServicesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <Layout>
        <div className='mb-global'>
            <PrimaryBanner src={Banner} src_mob={BannerMobile} banner_text={"E-Services"} alt={"E-Services"}/>
        </div>
        <div className='mb-global'>
            <Section02/>
        </div>
        <div className='mb-global'>
            <Center_Section_Primary
                src={sectionImage} 
                alt={'   our e-services'}
                title_black={"our "}
                title_purple={"e-services"} 
                text={"Effortless Shipping Solutions: Expert Export and Import Consultants for Smooth Customs Clearance."}
                text_box_width={"99%"}
                form={<ImportExportForm />}
            />
        </div>
    </Layout>
  )
}

export default EServicesPage
