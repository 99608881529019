import React, { useState } from 'react'
import './header.css'

const Mobile_Navlinks_dropdown = (props) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };


  return (
    <div className='navlink-dropdown'>
    <button className='sub-services-mobile' onClick={toggleDropdown}>
      {props.label}
      {isDropdownVisible ? (
        <svg
          className='float-end'
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='#8F3055'
        >
          <path
            d='M15 12.5L10 7.5L5 12.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : (
        <svg
          className='float-end'
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='#8F3055'
        >
          <path
            d='M5 7.5L10 12.5L15 7.5'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </button>
    {isDropdownVisible && props.data}
  </div>
);
};

export default Mobile_Navlinks_dropdown
