import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DataCard from './DataCard'

const DataCardsComponent = () => {
    const data= [
        { id : '01.', title: 'Transparent Pricing', text : 'We strongly believe in creating a future based on transparency in pricing with customers.'},
        { id : '02.', title: 'Real-Time Tracking', text : 'We maintain a ground presence in our operations sector, offering real-time tracking.'},
        { id : '03.', title: '100% Satisfied Customers', text : 'We leverage our experience to secure the best deals and partnerships with customer satisfaction across the country.'},
        { id : '04.', title: 'On-Time Delivery', text : 'Our team can provide highly flexible and tailored services to our clients.'},
        { id : '05.', title: '24.7 Online Support', text : 'Our team can offer 24*7 online support for all enquiries and concerns regarding the services.'},
        { id : '06.', title: 'Quality Service', text : 'We are well known for our quality and assurance, offering world-class services in the UAE.'},
    ]
  return (
    <div className='data-cards-component'>
        <Row>
            {data.map((data, index) => (
                <Col lg={4} sm={6} xs={6} key={index}>
                    <DataCard id={data.id} title={data.title} text={data.text}/>
                </Col>
            ))}
        </Row>
    </div>
  )
}

export default DataCardsComponent
