import React from 'react'
import Tabs_Data from './Tabs_Data'
import Title_Text from '../Section02/Title_Text'

const Section04 = () => {



  return (
    <div className='section04 container my-5'> 
        <Title_Text 
            title_white={"Major "} 
            title_purple={"Port Operations"}     
            text={"Streamlined Logistics: Trust Rahshiplog for Smooth Major Port Services."}
        />
        <Tabs_Data/>
    </div>
  )
}

export default Section04
