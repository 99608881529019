import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from './Card'
// import importImage from '../../../../assets/media/e-services/import.webp'
// import exportImage from '../../../../assets/media/e-services/export.webp'
import importImage from '../../../../assets/media/e-services/E-Service-Import.webp'
import exportImage from '../../../../assets/media/e-services/E-Service-Export.webp'

const Section02 = () => {
  return (
    <Container>
        <Row>
            <Col lg={6} className='card-col'>
                <Card 
                    src={importImage}
                    alt={'import'}
                    title={'IMPORT'}
                    text={'We provide import operational support and consulting services with the insights gained from our many years of experience in this industry.'}
                />
            </Col>
            <Col lg={6} className='card-col'>
                <Card 
                    src={exportImage}
                    alt={'export'}
                    title={'EXPORT'}
                    text={'We are export services professionals in the UAE who can assist shippers with export shipping logistics and the best way to move their goods via sea, truck and air for years.'}
                />
            </Col>
        </Row>
    </Container>
  )
}

export default Section02
