import React from 'react'
import PrimaryBanner from '../../../../global_components/Primary Banner/PrimaryBanner'
import BannerMobile from '../../../../../assets/media/3-2.jpg'
import Banner from '../../../../../assets/media/3.jpg'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Title_and_Text from '../../../../global_components/Title_and_Text/Title_and_Text'
import Form_01 from '../../../../Forms/Form_01'
import './Primary_Component.css'

const Primary_Component = (props) => {
  return (
    <div className='sub-service-page'>
        <div className='banner_desk mb-global'>
            <PrimaryBanner src={Banner} src_mob={BannerMobile} banner_text={"Services"} class="service-banner"/>
        </div>
        <Image src={props.mobileBanner_src} alt={props.alt} className='banner_mobile mb-global' style={{maxWidth: '100%'}}/>
        <Container className='mb-global second-sec'>
            <Row>
                <Col lg={7} className='image-block'>
                    <Image src={props.sectionImage_src} alt={props.alt}/>
                </Col>
                <Col lg={5} md={12} sm={12} className='d-flex align-items-center'>
                    <div>
                        <Title_and_Text
                            title_purple={props.title} 
                            text={props.text}
                            text_box_width={'90%'}
                        />
                        <div className='form-box'> <Form_01 serviceName={props.serviceName}/> </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Primary_Component
