import React from 'react'
import { Image } from 'react-bootstrap'
import '../AboutUs.css'
import { Link } from 'react-router-dom'

const Card = (props) => {
    const arrow_svg = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4.5 19.5L19.5 4.5M19.5 4.5H8.25M19.5 4.5V15.75" stroke="#8F3055" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>

  return (
    <Link to={props.link}>
        <div className='image-card'>
            <Image className='img-fluid' src={props.src} alt={props.alt} />  
            <div className='title-box'> <span className='title'>{props.title}</span>  <span className='title-box-svg'>{arrow_svg}</span> </div>
        </div>
    </Link>
  )
}

export default Card
