import React from 'react'
// import image from "../../../../assets/media/home/home-section-02-img.webp"
import image from "../../../../assets/media/home/Home_Section_02.webp"
import Right_Column from './Right_Column'

const Section02 = () => {
  return (
    <div className='container'>
      <div className='row my-4'>
        <img className='col-lg-7 col-md-12 my-4 home-section-2-img' src={image} alt="E-services"/>
        <div className='col-lg-5 col-md-12 my-4'>
          <Right_Column/>
        </div>
      </div>
    </div>
  )
}

export default Section02
