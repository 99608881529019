import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Title_and_Text from '../Title_and_Text/Title_and_Text'
import './CenterSection.css'

const Center_Section_Primary = (props) => {
  return (
    <Container className='mb-global Section02-primary'>
        <Row>
            <Col lg={7} md={12} sm={12}>
                <Image src={props.src} alt={props.alt}/>
            </Col>
            <Col lg={5} md={12} sm={12}>
                <Title_and_Text
                    title_black={props.title_black}
                    title_purple={props.title_purple} 
                    text={props.text}
                    text_box_width={props.text_box_width}
                />
                {props.form}
            </Col>
        </Row>
    </Container>
  )
}

export default Center_Section_Primary
