import React, { useEffect } from 'react'
import Layout from '../../../Layout/Layout'
import Primary_Component from './Sub-Service Primary Component/Primary_Component'
import sectionImage from '../../../../assets/media/services/land/land.webp'

const Land = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
        <Primary_Component
            mobileBanner_src={sectionImage}
            sectionImage_src={sectionImage}
            alt={'Land transportation'}
            title={'Land transportation'}
            text={'Our land transportation services assist you with reliable, secure, and on-time delivery regardless of the destination across the country.'}
            serviceName={"land"}
        />
    </Layout>
  )
}

export default Land
