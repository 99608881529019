import React from 'react';
import "../Footer/Footer.css";

const Copyright = () => {
  const style = {textDecoration: 'none', color: '#212121 '}
  const currentYear = new Date().getFullYear();

  return (
    <div className='copyright px-3'>
      <div>© {currentYear} Rahshiplog. All Rights Reserved. Design and Developed by <a href='https://mean3.com/' target='blank' style={style} >Mean3 Pvt Ltd.</a> </div>
    </div>
  );
}

export default Copyright;
