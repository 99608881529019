import React from 'react'
import PrimaryBanner from '../../global_components/Primary Banner/PrimaryBanner'
// import Banner from '../../../assets/media/services/Banner.webp'
import Banner from '../../../assets/media/2.jpg'
import  BannerMobile from '../../../assets/media/2-2.jpg'

const Section01 = () => {
  return (
        <div className='mb-global'>
            <PrimaryBanner src={Banner} src_mob={BannerMobile} banner_text={"Services"} alt={"Services"}/>
        </div>
  )
}

export default Section01
