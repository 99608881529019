import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ImageCard from './ImageCard';
// import SeaImage from '../../../../assets/media/about us/sea.webp';
// import AirImage from '../../../../assets/media/about us/air.webp';
// import LandImage from '../../../../assets/media/about us/land.webp';
// import LCLImage from '../../../../assets/media/about us/lcl.webp';
// import CCImage from '../../../../assets/media/about us/cc.webp';
import SeaImage from '../../../../assets/media/about us/sea-about.webp';
import AirImage from '../../../../assets/media/about us/air-about.webp';
import LandImage from '../../../../assets/media/about us/land-about.webp';
import LCLImage from '../../../../assets/media/about us/lcl-about.webp';
import CCImage from '../../../../assets/media/about us/custom-about.webp';

const CarouselComponent = () => {
  const cardData = [
    { src: SeaImage, title: 'Sea', link: '/sea-service', alt: 'sea service'},
    { src: AirImage, title: 'Air', link: '/air-service', alt: 'air service'},
    { src: LandImage, title: 'Land', link: '/land-service', alt: 'land service'},
    { src: LCLImage, title: 'LCL', link: '/lcl-service', alt: 'lcl service'},
    { src: CCImage, title: 'CC', link: '/cc-service', alt: 'cc service'},
  ];

  const responsive = {
    xl: { breakpoint: { max: 3000, min: 1200 }, items: 4 },
    lg: { breakpoint: { max: 1199, min: 992 }, items: 3 },
    md: { breakpoint: { max: 991, min: 768 }, items: 2 },
    sm: { breakpoint: { max: 767, min: 576 }, items: 2 },
    xs: { breakpoint: { max: 575, min: 0 }, items: 2 },
  };

  return (
    <div style={{marginTop: '4%'}}>
      <Carousel
        removeArrowOnDeviceType={['xl','lg','md','sm', 'xs']}
        responsive={responsive}
        showDots={true}
      >
        {cardData.map((card, index) => (
          <div key={index} className="carousel-card">
            <ImageCard src={card.src} title={card.title} link={card.link} alt={card.alt}/>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
